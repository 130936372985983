<!--
 * @Description: tagsView 刷新时的跳板页面
 * @Author: sunxiaodong
 * @Date: 2020-04-23 14:45:51
 * @LastEditors: ChenXueLin
 * @LastEditTime: 2021-12-17 18:58:22
 -->
<script>
export default {
  created() {
    const { params, query } = this.$route;
    const { path } = params;
    this.$router.replace({ path: "/" + path, query });
  },
  render: function(h) {
    return h();
  }
};
</script>
